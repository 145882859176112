<template>
  <div class="left_items">

    <!--        <left-buttons-comp></left-buttons-comp>-->
    <div style="width: 100%;display: flex;align-items: center;justify-content: space-between;margin: 5px 0">
      <input v-model="$store.state.searchTeamName" placeholder="팀명을 입력하세요" style="width: 70%;height: 32px;text-align: center;font-weight: bold"></input>
      <button @click="searchByTeamName" style="width: 29%;line-height: 32px;background: linear-gradient(180deg, #f6b64a, #9f6324);">검색</button>
    </div>
    <div style="width: 100%">
      <div class="item_title">
        종목 <span class="sub_title">stock selection</span>
      </div>
      <div class="item" :class="{'active':getSelectedGameType===sportsConst.GAME_TYPE_ALL}"
           @click="gameTypeChange(sportsConst.GAME_TYPE_ALL)">
        <div class="img"><img src="../../assets/images/icon/type/1/all.svg"></div>
        <div class="name">전체</div>
        <div class="gc"><span class="badge badge-gc">{{ alltypecount }}</span></div>
      </div>
      <div class="item" :class="{'active':getSelectedGameType===sportsConst.GAME_TYPE_SOCCER}"
           @click="gameTypeChange(sportsConst.GAME_TYPE_SOCCER)">
        <div class="img"><img src="../../assets/images/icon/type/1/football-ico.png"></div>
        <div class="name">축구</div>
        <div class="gc"><span class="badge badge-gc ">{{ soccer }}</span></div>
      </div>
      <div class="item"
           :class="{'active':getSelectedGameType===sportsConst.GAME_TYPE_BASKETBALL}"
           @click="gameTypeChange(sportsConst.GAME_TYPE_BASKETBALL)">
        <div class="img"><img src="../../assets/images/icon/type/1/basketball-ico.png"></div>
        <div class="name">농구</div>
        <div class="gc"><span class="badge badge-gc ">{{ basketball }}</span></div>
      </div>
      <div class="item"
           :class="{'active':getSelectedGameType===sportsConst.GAME_TYPE_BASEBALL}"
           @click="gameTypeChange(sportsConst.GAME_TYPE_BASEBALL)">
        <div class="img"><img src="../../assets/images/icon/type/1/baseball-ico.png"></div>
        <div class="name">야구</div>
        <div class="gc"><span class="badge badge-gc ">{{ baseball }}</span></div>
      </div>
      <div class="item" :class="{'active':getSelectedGameType===sportsConst.GAME_TYPE_VOLLEYBALL}"
           @click="gameTypeChange(sportsConst.GAME_TYPE_VOLLEYBALL)">
        <div class="img"><img src="../../assets/images/icon/type/1/volleyball-ico.png"></div>
        <div class="name">배구</div>
        <div class="gc"><span class="badge badge-gc ">{{ volleyball }}</span></div>
      </div>
      <div class="item" :class="{'active':getSelectedGameType===sportsConst.GAME_TYPE_HOCKEY}"
           @click="gameTypeChange(sportsConst.GAME_TYPE_HOCKEY)">
        <div class="img"><img src="../../assets/images/icon/type/1/hockey-ico.png"></div>
        <div class="name">하키</div>
        <div class="gc"><span class="badge badge-gc ">{{ hockey }}</span></div>
      </div>
      <div class="item" v-if="esports > 0" :class="{'active':getSelectedGameType===sportsConst.GAME_TYPE_ESPORTS}"
           @click="gameTypeChange(sportsConst.GAME_TYPE_ESPORTS)">
        <div class="img"><img src="../../assets/images/icon/type/1/esport-ico.png"></div>
        <div class="name">e-sports</div>
        <div class="gc"><span class="badge badge-gc ">{{ esports }}</span></div>
      </div>
      <div class="item" v-if="football > 0"
           :class="{'active':getSelectedGameType===sportsConst.GAME_TYPE_USA_FOOTBALL}"
           @click="gameTypeChange(sportsConst.GAME_TYPE_USA_FOOTBALL)">
        <div class="img"><img src="../../assets/images/icon/type/1/usa-football-ico.png"></div>
        <div class="name">미식축구</div>
        <div class="gc"><span class="badge badge-gc ">{{ football }}</span></div>
      </div>
      <div class="item" v-if="tennis > 0" :class="{'active':getSelectedGameType===sportsConst.GAME_TYPE_TENNIS}"
           @click="gameTypeChange(sportsConst.GAME_TYPE_TENNIS)">
        <div class="img"><img src="../../assets/images/icon/type/1/tennis-ico.png"></div>
        <div class="name">테니스</div>
        <div class="gc"><span class="badge badge-gc ">{{ tennis }}</span></div>
      </div>
      <div class="item" v-if="pba > 0" :class="{'active':getSelectedGameType===sportsConst.GAME_TYPE_PBA}"
           @click="gameTypeChange(sportsConst.GAME_TYPE_PBA)">
        <div class="img"><img src="../../assets/images/icon/type/1/pocketball-ico.svg"></div>
        <div class="name">당구</div>
        <div class="gc"><span class="badge badge-gc ">{{ pba }}</span></div>
      </div>

      <!--리그-->
      <div class="item_title">
        인기리그 <span class="sub_title">popular league</span>
      </div>
      <div class="item" v-for="(item,index) in popularLeagueList" @click="leagueSelector(item)" v-if="item.isShow"
           :key="item.id" :class="{'active':item.checked}">
        <div class="img" v-if="item.gameTypeId === sportsConst.GAME_TYPE_SOCCER"><img
            src="../../assets/images/icon/type/1/football-ico.png"></div>
        <div class="img" v-if="item.gameTypeId === sportsConst.GAME_TYPE_BASEBALL"><img
            src="../../assets/images/icon/type/1/baseball-ico.png"></div>
        <div class="img" v-if="item.gameTypeId === sportsConst.GAME_TYPE_BASKETBALL"><img
            src="../../assets/images/icon/type/1/basketball-ico.png"></div>
        <div class="img" v-if="item.gameTypeId === sportsConst.GAME_TYPE_VOLLEYBALL"><img
            src="../../assets/images/icon/type/1/volleyball-ico.png"></div>
        <div class="img" v-if="item.gameTypeId === sportsConst.GAME_TYPE_HOCKEY"><img
            src="../../assets/images/icon/type/1/hockey-ico.png"></div>
        <div class="img" v-if="item.gameTypeId === sportsConst.GAME_TYPE_USA_FOOTBALL"><img
            src="../../assets/images/icon/type/1/usa-football-ico.png"></div>
        <div class="img" v-if="item.gameTypeId === sportsConst.GAME_TYPE_ESPORTS"><img
            src="../../assets/images/icon/type/1/esport-ico.png"></div>
        <div class="img" v-if="item.gameTypeId === sportsConst.GAME_TYPE_TENNIS"><img
            src="../../assets/images/icon/type/1/tennis-ico.png"></div>
        <div class="img" v-if="item.gameTypeId === sportsConst.GAME_TYPE_BOXING"><img
            src="../../assets/images/icon/type/1/boxing.svg"></div>
        <div class="league_img">
          <img :src="item.imageUrl" alt="" style="margin-left: 5px">
        </div>
        <div class="name2">{{ item.leagueName }}</div>
        <div class="gc"><span class="badge badge-gc ">{{ item.gcount }}</span></div>
      </div>
      <!--리그-->
      <div class="item_title">
        리그 <span class="sub_title">league</span>
      </div>
      <div class="item" v-for="(item,index) in normalLeagueList" @click="leagueSelector(item)" v-if="item.isShow"
           :key="item.id" :class="{'active':item.checked}">
        <div class="img" v-if="item.gameTypeId === sportsConst.GAME_TYPE_SOCCER"><img
            src="../../assets/images/icon/type/1/football-ico.png"></div>
        <div class="img" v-if="item.gameTypeId === sportsConst.GAME_TYPE_BASEBALL"><img
            src="../../assets/images/icon/type/1/baseball-ico.png"></div>
        <div class="img" v-if="item.gameTypeId === sportsConst.GAME_TYPE_BASKETBALL"><img
            src="../../assets/images/icon/type/1/basketball-ico.png"></div>
        <div class="img" v-if="item.gameTypeId === sportsConst.GAME_TYPE_VOLLEYBALL"><img
            src="../../assets/images/icon/type/1/volleyball-ico.png"></div>
        <div class="img" v-if="item.gameTypeId === sportsConst.GAME_TYPE_HOCKEY"><img
            src="../../assets/images/icon/type/1/hockey-ico.png"></div>
        <div class="img" v-if="item.gameTypeId === sportsConst.GAME_TYPE_USA_FOOTBALL"><img
            src="../../assets/images/icon/type/1/usa-football-ico.png"></div>
        <div class="img" v-if="item.gameTypeId === sportsConst.GAME_TYPE_ESPORTS"><img
            src="../../assets/images/icon/type/1/esport-ico.png"></div>
        <div class="img" v-if="item.gameTypeId === sportsConst.GAME_TYPE_TENNIS"><img
            src="../../assets/images/icon/type/1/tennis-ico.png"></div>
        <div class="img" v-if="item.gameTypeId === sportsConst.GAME_TYPE_BOXING"><img
            src="../../assets/images/icon/type/1/boxing.svg"></div>
        <div class="league_img">
          <img :src="item.imageUrl" alt="" style="margin-left: 5px">
        </div>
        <div class="name2">{{ item.leagueName }}</div>
        <div class="gc"><span class="badge badge-gc ">{{ item.gcount }}</span></div>
      </div>
    </div>
  </div>
</template>

<script>
import sportsConst from "../../common/sportsConst";
import {getLeagues, getSportsGameCountGroupByType} from "../../network/sportsRequest";
import GameTypeSelectorComp from "./GameTypeSelectorComp";
import {mapGetters} from "vuex"
import {
  RECEIVE_CHECKED_GAME_TYPE, RECEIVE_CHECKED_LEAUGE, RECEIVE_CHECKED_LEAUGE_DEL, RECEIVE_CHECKED_LEAUGE_DELALL,
} from "../../store/mutation-types";
import LeftButtonsComp from "../LeftButtonsComp";

export default {
  name: "SportsLeftBarComp",
  components: {LeftButtonsComp, GameTypeSelectorComp},
  props: {
    displayPosition: {
      type: Number,
      default() {
        return sportsConst.GAME_DISPLAYPOSITION_1x2;
      }
    },
  },
  data() {
    return {
      leagueList: [],
      checkedLeagueList: [],
      isShowLeagueTab: false,
      sportsConst,
      gameType: 0,
      soccer: 0,
      baseball: 0,
      basketball: 0,
      volleyball: 0,
      football: 0,
      hockey: 0,
      esports: 0,
      tennis: 0,
      boxing: 0,
      pba: 0,
      alltypecount: 0,
    }
  },
  methods: {
    initLeagues() {
      getLeagues({'displayPosition': this.displayPosition}).then(res => {
        if (res.data.success) {
          this.leagueList = res.data.data
          //체크된 리그를 active
          this.leagueList.forEach(league => {
            if (this.getSelectedLeagueList.length > 0 && this.getSelectedLeagueList.findIndex((le) => {
              return league.id === le.id
            }) !== -1) {
              this.$set(league, 'checked', true)
            } else {
              this.$set(league, 'checked', false)
            }

            if (this.getSelectedGameType !== sportsConst.GAME_TYPE_ALL && this.getSelectedGameType === league.gameTypeId) {
              this.$set(league, 'isShow', true)
            } else {
              this.$set(league, 'isShow', false)
            }
            if (this.getSelectedGameType === sportsConst.GAME_TYPE_ALL) {
              this.$set(league, 'isShow', true)
            }
            if (this.getSelectedLeagueList.length === 0) {
              this.$set(league, 'checked', false)
            }
          })
        }
      })
    },
    leagueSelector(item) {
      //체크상태인 리그 삭제
      item.checked = !item.checked
      if (item.checked) {
        this.$store.commit(RECEIVE_CHECKED_LEAUGE, item)
      } else {
        //체크상태인 리그 삭제
        let index = this.getSelectedLeagueList.findIndex((s) => {
          return s.id === item.id
        })
        if (index !== -1) {
          this.$store.commit(RECEIVE_CHECKED_LEAUGE_DEL, index);
        }
      }
      if (this.$route.name !== 'sports' && this.$route.name !== 'sports_special') {
        this.$router.push({path: '/sports'})
      }
    },
    gameTypeChange(type) {
      this.$store.commit(RECEIVE_CHECKED_GAME_TYPE, type)
      this.$store.commit(RECEIVE_CHECKED_LEAUGE_DELALL)
      this.leagueList.forEach(league => {
        league.checked = false
        if (type === sportsConst.GAME_TYPE_ALL) {
          league.isShow = true
        } else {
          league.isShow = league.gameTypeId === this.getSelectedGameType;
        }
      })
      if (this.$route.name !== 'sports' && this.$route.name !== 'sports_special') {
        this.$router.push({path: '/sports'})
        return false;
      }
    },
    hideLeagueTab() {
      this.isShowLeagueTab = false;
    },
    searchByTeamName() {
      this.$bus.$emit('seachByTeamName')
      this.$bus.$emit('leftBarHandle', false)
    }
  },
  computed: {
    ...mapGetters({
      'getSelectedGameType': 'getSelectedGameType',
      'getSelectedLeagueList': 'getSelectedLeagueList',
    }),
    /*인기리그 정리*/
    popularLeagueList() {
      return this.leagueList.filter(leauge => {
        return leauge.popular === sportsConst.IS_POPULAR_LEAGUE_YES
      });
    },
    /*비인기리그 정리*/
    normalLeagueList() {
      return this.leagueList.filter(leauge => {
        return leauge.popular === sportsConst.IS_POPULAR_LEAGUE_NOT
      });
    }
  },
  created() {
    this.$store.state.selectedGameType = sportsConst.GAME_TYPE_ALL
    getSportsGameCountGroupByType(sportsConst.INPLAY_NOT).then(res => {

      if (res.data.success) {
        let map = res.data.data;
        this.soccer = map[sportsConst.GAME_TYPE_SOCCER] !== undefined ? map[sportsConst.GAME_TYPE_SOCCER] : 0
        this.baseball = map[sportsConst.GAME_TYPE_BASEBALL] !== undefined ? map[sportsConst.GAME_TYPE_BASEBALL] : 0
        this.basketball = map[sportsConst.GAME_TYPE_BASKETBALL] !== undefined ? map[sportsConst.GAME_TYPE_BASKETBALL] : 0
        this.volleyball = map[sportsConst.GAME_TYPE_VOLLEYBALL] !== undefined ? map[sportsConst.GAME_TYPE_VOLLEYBALL] : 0
        this.hockey = map[sportsConst.GAME_TYPE_HOCKEY] !== undefined ? map[sportsConst.GAME_TYPE_HOCKEY] : 0
        this.football = map[sportsConst.GAME_TYPE_USA_FOOTBALL] !== undefined ? map[sportsConst.GAME_TYPE_USA_FOOTBALL] : 0
        this.esports = map[sportsConst.GAME_TYPE_ESPORTS] !== undefined ? map[sportsConst.GAME_TYPE_ESPORTS] : 0
        this.tennis = map[sportsConst.GAME_TYPE_TENNIS] !== undefined ? map[sportsConst.GAME_TYPE_TENNIS] : 0
        this.pba = map[sportsConst.GAME_TYPE_PBA] !== undefined ? map[sportsConst.GAME_TYPE_PBA] : 0
        this.alltypecount = this.soccer + this.baseball + this.basketball + this.volleyball + this.hockey
            + this.football + this.esports + this.pba;
      }
    })

    this.initLeagues()
  },
  watch: {
    displayPosition() {
      this.initLeagues()
    },
  }
}
</script>

<style scoped>

</style>